<template>
    <div class="container" id="znzj">
        <div class="aside" >
           <side-bar ref="sideBar" class="sidebar-container" />
        </div>

        <section class="mainContent">
            <div class="mainContainer" v-loading="loading" element-loading-text="数据加载中,请稍候...">
                <div class="chat-top" style="border: none;">
                    <!-- <div class="chat-search-box">
                        <el-input v-model="searchVal" placeholder="search..."></el-input>
                        <span class="chat-search-icon"></span>
                    </div>
                    <div class="chat-switch-box">开启反演
                        <el-switch
                            v-model="inversionVal"
                            active-color="#4d82ff"
                            inactive-color="#b7bbc2"
                            active-value="100"
                            inactive-value="0"
                            :disabled="inversList.length==0"
                            @change="handleInversion">
                        </el-switch>
                    </div> -->
                </div>

                <div class="title-text">
                    <div class="title-text-t">国家重点研发计划“主动健康和人口老龄化科技应对”重点专项</div>
                    <div class="title-text-t">《国家科学健身图数据库和推荐系统平台研究》</div>
                </div>
                
                <div class="chat-mainer">
                    <div class="chat-content">
                        <div class="chat_main chat-welcome" v-if="!isShowChat">
                            <div class="welcome">
                                <h2 style="font-size: 22px;font-weight: bold;margin-top: 18px;">{{welData.title}}</h2>
                                <div class="chat-welImg" style="margin-top: 50px;"><img :src="welData.imgUrl" /></div>
                                <!-- <h2 style="font-size: 22px;font-weight: bold;margin-top: 18px;">{{welData.title}}</h2> -->
                                <!-- <p>{{welData.txt}}</p> -->
                            </div>
                        </div>
                        <div class="chat_main" id="chat_main" v-else></div>
                        <el-row class="chat_buttom_new">
                            <tag-tips :tipData="tipData"></tag-tips>
                            <div class="chat_buttom">
                                <div class="chat_text">
                                    <textarea class="chat_ipt" v-model="chatText" @keyup="handleChatIptEnter" placeholder="请输入您的问题" autofocus></textarea>
                                </div>
                                <div class="chat_btn_box">
                                    <div class="chat_btn" :class="chatText!=null && chatText!=''? 'active':''" @click="handleChatAdd"></div>
                                    <!-- <div class="switch_btn" @click="handleChatSwitch"><img :src="require('../../assets/icon/switch-' + (!chatSwitch?'off.png':'on.png'))"/></div> -->
                                </div>
                            </div>
                        </el-row>
                    </div>

                    <div class="chat-invers" v-if="inversionVal==='100'&&inversList.length>0">
                        <div class="chat-invers-box">
                            <el-tabs v-model="activeName" @tab-click="handleClick" ref="tabPdf" closable @tab-remove="removeTab">
                                <el-tab-pane v-for="(tab,index) in tabList" :label="tab.label" :name="tab.name" :key="'tab'+tab.name">
                                    <div class="chat-invers-content">
                                        <iframe :src="iframeUrlData[index].url" :id="'myPdf'+tab.name" ref="myPdf" width="100%" height="100%" frameborder="0"></iframe>
                                    </div>
                                </el-tab-pane>
                            </el-tabs>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <set-dialog :dialogSetVisible="dialogSetVisible"></set-dialog>
        <info-dialog :dialogSysVisible="dialogSysVisible"></info-dialog>
    </div>
</template>

<script>
import TagTips from '../../components/main/TagTips.vue';
import SideBar from '../../components/Sidebar/index.vue'
import SetDialog from '../../components/Sidebar/SetDialog.vue';
import InfoDialog from '../../components/Sidebar/InfoDialog.vue';
import { knowledgeFault } from '@/api/inversion'
export default {
  name:"Main",
  components: {
      SideBar,
    SetDialog,
    InfoDialog,
    TagTips,
  },
  data() {
    return {
        appId: 'ZNZJ',
        sessionId: null,
        rereply: 0,
        question: '',
        searchVal: '',
        inversionVal: '0',
        loading: false,
        chatText: null,//聊天文本框值
        chatSwitch: false, //聊天开关
        infoLoad: false,
        visible: false, //评价框开关
        evaValue: 4, //评分
        likeTo: false, //赞
        stepOn: false, //踩
        pdfUrl: require('../../assets/icon/icon_pdf.png'),
        replyText: '',
        inversList: [],
        tabList: [], //tab列表
        activeName: 'first',
        iframeUrl: '',
        iframeUrlData: [],
        isShowChat: false,
        welData: {
            imgUrl: require('../../assets/image/pic.png'),
            title: '健身运动知识智能查询问答平台',
            txt: '分析理解用户问题，通过健康体育垂直领域大模型，挖掘知识生成回答，并提供关联电子书',
            // title: '分析理解用户问题，通过健康体育垂直领域大模型，挖掘知识生成回答，并提供关联电子书',
            // txt: '• 基于自生成指令对齐的数据集构建技术 • 基于低秩矩阵的大模型微调技术 • 基于注意力机制的信息抽取技术'
        },
        dialogSetVisible: false, //设置PDF
        dialogSysVisible: false, //系统PDF
        tipData: []
    }
  },
  created() {
      setInterval(function(){ 
        //聊天iframe撑开 
        document.querySelectorAll("iframe").forEach(function(item){
            let cm = item.contentDocument.getElementById("chat_main")
            if(cm && item.height!=cm.clientHeight){
                item.ch = cm.clientHeight
                item.height = cm.clientHeight
               
                //聊天滚动最下
                let bcm = document.getElementById("chat_main")
                if(bcm){
                    bcm.scrollTop = bcm.scrollHeight
                }
            }
        })
    }, 500);
  },
  mounted() {
    this.$refs.sideBar.handleMenuClick(1)
    window.changeLoading = this.changeLoading;
    window.closeChat = this.closeChat;
    window.handleInvers = this.handleInvers;
    window.refreshData = this.refreshData;
    window.handleText = this.handleText
    this.refreshData();
  },
  methods: {
    queryInversion() {
        let chat = document.getElementById('chat_main');
        let iframe = document.createElement("iframe")
        iframe.setAttribute("src", 'robot?appId=' + this.appId + '&question=' + this.question + '&sessionId=' + this.sessionId)
        iframe.setAttribute("width", '100%')
        iframe.setAttribute("frameborder", '0')
        iframe.setAttribute("allowfullscreen", 'true')
        iframe.setAttribute("scrolling", 'no')
        iframe.setAttribute("ch", 0)
        iframe.setAttribute("style", 'display: none; margin-bottom:40px')
        chat.appendChild(iframe)
    },
    handleChatAdd(){ //手写输入
        if(this.chatText==null){
            this.$message.warning('请输入有效内容')
            return
        } else {
            let text = this.chatText
            if(text){
                text = text.replace(new RegExp('\\r\\n','gm'),'')
                text = text.replace(new RegExp('\\n','gm'),'')
                
                if(text!=='') {
                    this.chatText = null
                    this.isShowChat = true
                    this.question = text
                    this.$nextTick(() => {
                        this.handleAddVoiceContent(1, text);
                        this.queryInversion()
                    })
                } else {
                    this.chatText = null
                    this.$message.warning('请输入有效内容')
                }
            } else {
                this.$message.warning('请输入有效内容')
            }
        }
    },
    refreshData() {
        knowledgeFault({appId:this.appId,count:1}).then(res => {
            console.log("knowledgeFault")
            if(res && res.query_list.length>0) {
                this.tipData = res.query_list
            }
        }).catch(err => {})
    },
    handleText(text) {
        if(text) {
            this.chatText = text
        }
    },
    handleChatIptEnter(event){ //手写回车
        if (event.keyCode == "13") {
            event.preventDefault() // 阻止浏览器默认换行操作
            this.handleChatAdd()
            return false
        }
    },
    handleAddVoiceContent(type, text){ //展示语音内容 type 1加载中 2展示x
      let chat = document.getElementById('chat_main');
      let item = document.querySelector('.msg-box-r')
      let logoItem = document.querySelector('.msg-l-logo')

      if(type==1){
        let box = document.createElement("div")
        box.setAttribute("class", "msg-box-r")
        chat.appendChild(box)

        item = document.createElement("div")
        item.innerHTML = text
        item.setAttribute("class", "msg-l")
        box.appendChild(item)

        logoItem = document.createElement("div")
        logoItem.setAttribute("class", "msg-l-logo")
        box.appendChild(logoItem)
        //聊天滚动最下
        let bcm = document.getElementById("chat_main")
        if(bcm){
            bcm.scrollTop = bcm.scrollHeight
        }
      }
    },
    handleChatSwitch(){//聊天输入方式切换
        this.chatSwitch = !this.chatSwitch
    },
    handleChangeChatText(text){ //改变聊天输入框的内容
        this.chatText = text
        let ipt = document.getElementsByClassName(".chat_ipt")[0]
        if(ipt){
            ipt.focus();
        }
    },
    handleInversion() {
        this.$nextTick(() => {
            let cm = document.querySelector('.chat-content');
            let main = document.getElementById('chat_main');
            if(this.inversionVal==='100' && this.inversList.length > 0) {
                main.setAttribute("class","chat_main fanyan")
                cm.setAttribute("style","width:50%")
            } else {
                main.setAttribute("class","chat_main")
                cm.setAttribute("style","width:100%")
            }
        })
    },
    handleClick(tab, event) {
        let index = Number(tab.index)
        this.$nextTick(() => {
            console.log(this.tabList)
            this.iframeUrl = this.tabList[index].iframeUrl
        })
    },
    handleInvers(data) {
        this.$nextTick(() => {
            let main = document.getElementById('chat_main');
            let cm = document.querySelector('.chat-content');
            if(data) {
                cm.setAttribute("style","width:50%")
                main.setAttribute("class","chat_main fanyan")
                if(this.inversList.length === 0) {
                    this.inversList.push(data)
                }
                this.inversionVal = '100'
                let file = data.fileUrl
                let txt = data.pageContent
                let currentObj = {label: data.title, name: data.title,iframeUrl:this.iframeUrl}
                this.activeName = data.title
                // let iframeUrl = 'pdf/web/viewer.html?file='+ encodeURIComponent('/iframe_base/'+file) +'&text=' + encodeURIComponent(txt)
                let iframeUrl = 'pdf/web/viewer.html?file='+ encodeURIComponent(file) +'&text=' + encodeURIComponent(txt)
                let iframeObj = {url:iframeUrl,name: data.title}
                if(this.tabList.length > 0) {
                    if(JSON.stringify(this.tabList).indexOf(JSON.stringify(currentObj)) == -1) {
                        this.tabList.push(currentObj)
                        this.iframeUrlData.push(iframeObj)
                    }
                } else {
                    this.tabList.push(currentObj)
                    this.iframeUrlData.push(iframeObj) 
                }
            } else {
                cm.setAttribute("style","width:100%")
                main.setAttribute("class","chat_main")
                this.inversionVal = '0'
            }
        })
    },
    regener() {
        this.rereply = 1
        this.queryInversionData({});
    },
    closeChat() {
        this.isShowChat = false
        //聊天滚动最下
        let bcm = document.getElementById("chat_main")
        let ccm = document.querySelector('.chat-content');
        if(bcm){
            ccm.setAttribute("style","width:100%")
            bcm.innerHTML = ''
            this.inversionVal = '0'
        }
    },
    changeLoading(loading){
        this.loading = loading
        if(!loading){
            document.querySelectorAll("iframe").forEach(function(item){
                item.style.display='block'
            })
        }
    },
    removeTab(targetName) {
        let tabs = this.tabList;
        let iframeUrlData = this.iframeUrlData;
        let activeName = this.activeName;
        tabs.map((x,index) => {
            if(x.name === targetName) {
                let nextTab = tabs[index + 1] || tabs[index - 1];
                if (nextTab) {
                    activeName = nextTab.name;
                } 
            }
            this.activeName = activeName;
            this.tabList = tabs.filter(tab => tab.name !== targetName);
            if(this.tabList.length < 1) {
                let cm = document.querySelector('.chat-content');
                cm.setAttribute("style","width:100%")
                this.inversionVal = '0'
            }
        })
        this.iframeUrlData = iframeUrlData.filter(item => item.name !== targetName)
    }
  }
}
</script>

<style scoped>
/* tab 样式*/
/deep/.el-tabs__nav-scroll {
    max-width: 735px;
}
/deep/.el-tabs__active-bar {
    margin-left: -22px;
    width: 122px !important;
}
/deep/.el-tabs__item {
  padding: 0 20px 0 22px !important;
  background: url(../../assets/icon/icon_pdf.png) no-repeat;
  background-size: 14px 16px;
  background-position: 3px 12px;
  max-width: 130px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
/deep/.el-tabs__item, .el-tabs__item.is-active {
  color: #333 !important;
}
/deep/.el-tabs__item.is-focus,.el-tabs__item.is-focus:focus, .el-tabs__item.is-focus:focus:active {
    border: none !important;
}
/deep/ .el-tabs__item .el-icon-close {
    position: absolute;
    right: 7px;
    top: 15px;
}
/deep/ .el-tabs__item .el-icon-close:hover {
    background-color: #666;
}
.mainContent .chat_main {
    height: calc(100% - 162px);
}

.mainContent{
    position: relative;
}

.title-text{
    position: absolute;
    right: 20px;
    top: 15px;
    text-align: center;
}

.title-text-t{
    font-size: 12px;
    color: #000;
}

.title-text-t+.title-text-t{
    margin-top: 2px;
}
</style>